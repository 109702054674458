<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="deliveryReportList.data && deliveryReportList.data.length > 0 && showHeaders.length > 0 ? '380' : ''"
              :style="{maxHeight: !deliveryReportList.length ? '810px' : ''}"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="showHeaders"
              :items="deliveryReportList.data"
              style="word-break: break-word"
              :search="search"
              item-class="py-4"
              fixed-header
              :loading="isProgressCircular"
              :items-per-page="limitData"
              :page.sync="page"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              must-sort
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <span :ref="`row-${item.id}`">{{ item.id }}</span>
              </template>
              <template v-slot:[`item.after_discount`]="{ item }">
                <span>{{numberWithCommas(item.after_discount)}}</span>
              </template>
              <template v-slot:[`item.grand_total`]="{ item }">
                <span>{{numberWithCommas(item.grand_total)}}</span>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <span>{{numberWithCommas(item.total)}}</span>
              </template>
              <template v-slot:[`item.vat`]="{ item }">
                <span>{{!item.vat ? null : item.vat}}</span>
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                <span>{{!item.discount ? null : item.discount}}</span>
              </template>
              <template v-slot:[`item.status_one`]="{ item }">
                <span>{{item.status_one && getSelectedStatusOne(item.status_one).length > 0 ? getSelectedStatusOne(item.status_one)[0].description : ''}}</span>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("delivery.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf class="me-2 mb-1" :disabled="deliveryReportList.data && deliveryReportList.data.length > 0" :model="deliveryReportList" :payload="payload" type="DeliveryReportPDF"/>
                  <ExcelAndResetFilterCommon :data="(deliveryReportList.data && deliveryReportList.data.length > 0)" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-form v-model="valid" ref="form">
                <v-card-text>
                  <v-row :dense="true">
                    <v-col cols="4">
                      <v-autocomplete
                          v-if="!type"
                          hide-details="auto"
                          v-model="selectedStatus"
                          :items="statusList"
                          item-text="name"
                          item-value="id"
                          flat
                          return-object
                          single-line
                          :label="$t('delivery.field.status')"
                      />
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                            v-if="!type"
                            hide-details="auto"
                            v-model="selectedCustomer"
                            :items="customerList.data"
                            item-text="name_1"
                            item-value="id"
                            clearable
                            flat
                            return-object
                            single-line
                            :search-input="customerSearch"
                            :label="$t('delivery.field.customer')"
                        >
                            <template v-slot:append-item>
                                <div v-show="hasNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                    <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    />
                                </div>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                            v-else
                            readonly
                            :dense="true"
                            hide-details="auto"
                            v-model="selectCustomerForDialog.name_1"
                            :label="$t('delivery.field.customer')"
                            single-line
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                          hide-details="auto"
                          v-model="selectedCustomerGroup"
                          :items="customerGroupValue"
                          item-text="description"
                          item-value="id"
                          clearable
                          flat
                          return-object
                          :label="$t('delivery.customerGroup')"
                        />
                    </v-col>
                  </v-row>
                  <v-row :dense="true">
                    <v-col cols="4">
                        <v-autocomplete
                            v-if="!type"
                            hide-details="auto"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            clearable
                            flat
                            return-object
                            single-line
                            :search-input.sync="employeeSearch"
                            :label="$t('delivery.field.agent')"
                        >
                            <template v-slot:append-item>
                                <div v-show="hasEmployeeNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                    <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    />
                                </div>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                            v-else
                            readonly
                            :dense="true"
                            hide-details="auto"
                            v-model="selectEmployeeForDialog.name"
                            :label="$t('delivery.field.agent')"
                            single-line
                        />
                    </v-col>
                    <v-col cols="8" class="text-end d-flex">
                        <v-menu
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="deliveryDateFormat"
                            transition="scale-transition"
                            offset-y
                            :nudge-left="40"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    hide-details="auto"
                                    class="me-2"
                                    single-line
                                    v-model="delivery_date"
                                    :rules="profileById.date_format ? dateValid : dateValid2"
                                    :label="$t('delivery.from')"
                                    @blur="deliveryDateFormat = profileById.date_format ? parseDateYYYYMMDD(delivery_date) : delivery_date"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="deliveryDateFormat"
                                @change="$refs.startDateMenu.save(deliveryDateFormat)"
                            >
                              <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-menu
                            ref="endDateMenu"
                            v-model="endDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="deliveryValidityDateFormat"
                            transition="scale-transition"
                            offset-y
                            :nudge-left="160"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    hide-details="auto"
                                    single-line
                                    v-model="delivery_validity"
                                    :rules="profileById.date_format ? dateValid : dateValid2"
                                    :label="$t('delivery.to')"
                                    @blur="deliveryValidityDateFormat = profileById.date_format ? parseDateYYYYMMDD(delivery_validity) : delivery_validity"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                :min="deliveryDateFormat ? deliveryDateFormat : ''"
                                v-model="deliveryValidityDateFormat"
                                @change="$refs.endDateMenu.save(deliveryValidityDateFormat)"
                            >
                              <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="text-center mt-3">
                      <ExecuteAndRestCommon :valid="valid" @reset="reset" @paginate="paginate(false)" />
                      <v-dialog
                        dense
                        v-model="dialog"
                        scrollable
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            color="primary"
                            class="float-end"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left> mdi-view-comfy </v-icon>
                            {{ $t("delivery.fieldView") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-row class="align-center ma-0">
                            <v-col>
                              <v-card-title class="primary--text">
                                <v-icon left color="primary">mdi-land-fields</v-icon>
                                  <h6 class="font-weight-regular">
                                    {{ $t("delivery.selectFields") }}
                                  </h6>
                              </v-card-title>
                            </v-col>
                            <v-col>
                              <v-btn x-small color="primary" class="float-end mx-3" @click="updateHeader">{{ $t('delivery.update') }}</v-btn>
                            </v-col>
                          </v-row>
                          <v-divider />
                          <v-card-text style="height: 300px;">
                            <v-select
                              dense
                              class="mt-2"
                              hide-details="auto"
                              v-model="selectedHeaders"
                              :items="headers"
                              item-text="text"
                              item-value="id"
                              return-object
                              clearable
                              multiple
                              outlined
                              :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                              :label="$t('delivery.selectFields')"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  @mousedown.prevent
                                  @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-checkbox dense v-model="selectFields" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{$t('delivery.selectAll')}}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2" />
                              </template>
                            </v-select>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("delivery.debitReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        :autofocus="type ? true : false"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:[`item.delivery_date`]="props">
                <span>{{ props.item.delivery_date  && profileById.date_format ? formatDateDDMMYYYY(props.item.delivery_date):new Date(props.item.delivery_date).toISOString().substring(0,10) }}</span>
              </template>
              <template v-slot:[`item.status`]="props">
                {{ statusList.filter(x => x.id == props.item.status)[0].name }}
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="5">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{ deliveryReportList.data && deliveryReportList.data.length && showHeaders.length > 0 ? numberOfRows : 0 }}</span>
                  </v-col>
                  <v-col class="mx-auto" cols="7">
                    <v-row dense>
                      <v-col cols="8" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                        <span class="pl-3 pt-2 font-weight-bold">{{$t('delivery.total')}}: 
                         {{deliveryReportList && deliveryReportList.totalPerReport ? numberWithCommas(deliveryReportList.totalPerReport) : 0}}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="deliveryReportList.data ? pageCount : 0"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{ $t("common.noDataFound") }}</v-card-text>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import exportFromJSON from 'export-from-json';
import { getAuxiliaryZtableValueByName } from "@/utils";
import {formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate} from '@/utils';
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
    name: "DeliveryReport",
    props: ['type', 'selectCustomerForDialog', 'selectEmployeeForDialog'],
    components: {
        ListSkeleton,
        HtmlToPdf,
        ExcelAndResetFilterCommon,
        ExecuteAndRestCommon
    },
    mixins: [validationMixin, intersectionMixin, searchMixin],
    data() {
        return {
            listSkeleton: false,
            dialog: false,
            selectedHeaders: [],
            selectFields: false,
            search: this.$route.query.search || "",
            limitData: this.limit,
            limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
            page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
            pagination: null,
            selectedStatus: {
                id: this.$route.query.status === 'all' ? null : this.$route.query.status === 'closed' ? 1 : this.$route.query.status === 'cancelled' ? 2 : this.$route.query.status === 'open' && 0 || null
            },
            startDateMenu: false,
            endDateMenu: false,
            deliveryDateFormat:'',
            deliveryValidityDateFormat:'',
            delivery_date: this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0,10),
            delivery_validity: this.$route.query.endDate || new Date().toISOString().substring(0,10),
            selectedCustomer: {
                id: Number(this.$route.query.custId) || null
            },
            selectedEmployee: {
                id: Number(this.$route.query.empId) || null
            },
            customerPage: 1,
            customerLimit: 10,
            employeePage: 1,
            employeeLimit: 10,
            employeeSearch: '',
            selectedCustomerGroup: null,
            customerGroupValue: [],
            valid: false,
            numberOfRows: "",
            custom: {id: 111111111111111, description: this.$t('invoice.empty')},
            deliveryNoteStatus: [],
            isProgressCircular: false,
        };
    },
    computed: {
        params() {
            return {
                search: this.search,
                customer_id: this.selectedCustomer?.id,
                employee_id: this.selectedEmployee?.id,
                selectedStatus: this.selectedStatus?.id,
                delivery_date: this.delivery_date,
                delivery_validity: this.delivery_validity,
                customer_group_id: this.selectedCustomerGroup?.id,
            };
        },
        payload() {
          return {
            customer_id: this.selectedCustomer?.name_1,
            employee_id: this.selectedEmployee?.name,
            selectedStatus: this.selectedStatus?.name,
            delivery_date: this.delivery_date,
            delivery_validity: this.delivery_validity,
            customer_group_id: this.selectedCustomerGroup?.description,
          }
        },
        selectAllHeaders() {
            return this.selectedHeaders.length === this.headers.length
        },
        ...mapGetters({
            deliveryReportList: "delivery/deliveryReportList",
            locale: "locale",
            showHeaders: "deliveryReport/showHeaders",
            accessRight: 'accessRight',
            pageChange: "deliveryReport/pageChange",
            profileById: 'profile/profileById',
            customerList: 'customer/customerList',
            employeeList: 'employee/employeeList',
            rowIndex: "delivery/rowIndex",
        }),
        statusList() {
            return [
                { name: this.$t('delivery.field.all'), id: null },
                { name: this.$t('delivery.field.open'), id: 0 },
                { name: this.$t('delivery.field.finished'), id: 1 },
                { name: this.$t('delivery.field.canceled'), id: 2 }
            ]
        },
        computedDateFormatted () {
            return this.profileById.date_format ? this.formatDateDDMMYYYY(this.deliveryDateFormat) : this.deliveryDateFormat
        },
        computedValideDateFormat () {
            return this.profileById.date_format ? this.formatDateDDMMYYYY(this.deliveryValidityDateFormat) : this.deliveryValidityDateFormat
        },
        headers() {
            return [
                { text: this.$t("documentSeries.docNumber"), value: "delivery_note_document_number", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("documentSeries.series"), value: "document_number_series", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.customerName"), value: "customer_name", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.email"), value: "email", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.address"), value: "address", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.town"), value: "town", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.country"), value: "country", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.agent"), value: "employee.name", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.authorizedDealerNo"), value: "authorized_dealer_no", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.cel"), value: "cellular", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.contactId"), value: "contact_id", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.contact"), value: "contact.name", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.customerId"), value: "customer.id", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.discountPrecentage"), value: "discount_percentage", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.deliveryStatus"), value: "status_one", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.employeeId"), value: "employee_id", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.fax"), value: "fax", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.quotationId"), value: "quotation_id", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.orderId"), value: "order_id", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.customerOrderNo"), value: "customer_order_no", width: "150px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.tel1"), value: "phone_1", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.tel2"), value: "phone_2", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.deliveryTime"), value: "delivery_time", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.deliveryDate"), value: "delivery_date", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.deliveryCompany"), value: "delivery_company", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.deliveryNo"), value: "delivery_no", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.carNo"), value: "car_no", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.remarks"), value: "remarks", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.status"), value: "status", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.year"), value: "year", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.proformaInvoiceId"), value: "proforma_invoice_id", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.title"), value: "title", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.total"), value: "total", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.discount"), value: "discount", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.afterDiscount"), value: "after_discount", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.vat"), value: "vat", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.grandTotal"), value: "grand_total", width: "130px", align: "start", class: "px-1", sortable: true },     
                { text: this.$t("delivery.field.vatPrecentage"), value: "vat_percentage", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.createdAt"), value: "created_at", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("delivery.field.updatedAt"), value: "updated_at", width: "180px", align: "start", class: "px-1", sortable: true },
            ]
        },
        empty() {
          return this.$t('invoice.empty')
        }
    },
    created() {
        if (this.showHeaders.length == 0) {
          this.$store.commit('deliveryReport/SHOW_HEADERS', this.headers)
        }
        this.page = this.pageChange.page;
        this.limit = this.pageChange.limit;
        this.limitData = this.limit;
    },
    beforeDestroy() {
        this.$store.commit("deliveryReport/SHOW_PAGE_CHANGE", {page: this.pagination.page, limit: this.pagination.itemsPerPage})
    },
    destroyed() {
      if (!this.$route.params.delivery_id) {
        this.$store.commit('delivery/SET_DELIVERY_REPORT', [])
      }
    },
    watch: {
        empty() {
          this.custom.description = this.empty
        },
        pagination: {
            handler(newVal) {
                if (this.pagination) {
                this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10
                }
            },
            deep: true
        },
        selectCustomerForDialog() {
            this.selectedCustomer = this.selectCustomerForDialog;
        },
        selectEmployeeForDialog() {
          this.selectedEmployee = this.selectEmployeeForDialog;
        },
        type: {
            handler() {
                this.search = '';
                this.$emit('update:selectCustomerForDialog', null)
                this.$emit('update:selectEmployeeForDialog', null)
            }
        },
        deliveryDateFormat () {
            this.delivery_date = this.profileById.date_format ? this.formatDateDDMMYYYY(this.deliveryDateFormat) : this.deliveryDateFormat
        },
        deliveryValidityDateFormat () {
            this.delivery_validity = this.profileById.date_format ? this.formatDateDDMMYYYY(this.deliveryValidityDateFormat) : this.deliveryValidityDateFormat
        },
        profileById() {
            this.delivery_date =  this.profileById.date_format ? this.formatDateDDMMYYYY(this.delivery_date) : this.delivery_date
            this.deliveryDateFormat =  this.profileById.date_format ? parseDateYYYYMMDD(this.delivery_date) : this.delivery_date
            this.delivery_validity =  this.profileById.date_format ? this.formatDateDDMMYYYY(this.delivery_validity) : this.delivery_validity
            this.deliveryValidityDateFormat =  this.profileById.date_format ? parseDateYYYYMMDD(this.delivery_validity) : this.delivery_validity
        },
        customerLimit() {
            this.$store.dispatch('customer/GetCustomer', {page: 1, limit: this.customerLimit, order_by: 'name_1|asc'});
        },
        employeeLimit() {
            this.$store.dispatch('employee/GetEmployee', {page: 1, limit: this.employeeLimit, order_by: 'name|asc'});
        },
        employeeSearch(val) {
            this.$store.dispatch('employee/GetEmployee', {page: 1, limit: 10, where_like: val ? 'name|'+val : val, order_by: 'name|asc'});
        },
        '$route.query': {
            handler(newVal, oldVal) {
                if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length) 
                this.search = this.$route.query.search || ""
                this.delivery_date = this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0,10),
                this.delivery_validity = this.$route.query.endDate || new Date().toISOString().substring(0,10)
                this.selectedCustomer = {
                    id: Number(this.$route.query.custId) || null
                }
                this.selectedEmployee = {
                  id: Number(this.$route.query.empId) || null
                }
                this.selectedStatus = {
                    id: this.$route.query.status === 'all' ? null : this.$route.query.status === 'closed' ? 1 : this.$route.query.status === 'cancelled' ? 2 : this.$route.query.status === 'open' && 0
                }
            }
        },
        selectedHeaders() {
            if (this.selectedHeaders.length === this.headers.length) {
                this.selectFields = true
            } else {
                this.selectFields = false
            }
        },
        locale() {
            this.updateHeader();
        }
    },
    async mounted() {
        this.listSkeleton = true;
        this.selectedHeaders = this.showHeaders
        this.page = this.pageChange.page;
        this.limit = this.pageChange.limit;
        this.limitData = this.limit;
        // this.$store.dispatch("delivery/GetDelivery");
        this.$store.dispatch('customer/GetCustomer', {page: this.customerPage, limit: this.customerLimit, order_by: 'name_1|asc'});
        this.$store.dispatch('employee/GetEmployee', {page: this.employeePage, limit: this.employeeLimit, order_by: 'name|asc'});
        getAuxiliaryZtableValueByName(2).then((tableCustomerValue) => {
          if (Object.keys(tableCustomerValue).length > 0) {
            this.customerGroupValue = tableCustomerValue.ztables.sort((a, b) =>
              a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
                ? 1
                : -1
            );
            this.customerGroupValue = [this.custom, ...this.customerGroupValue]
          }
        });
        let tableNameValue = await getAuxiliaryZtableValueByName(13);
        if(Object.keys(tableNameValue).length > 0) {
            this.deliveryNoteStatus = tableNameValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
        }
        let rowIndex = this.$store.state.delivery.rowIndex;
        if (rowIndex && this.deliveryReportList?.data?.length > 0) {
          this.$refs[`row-${rowIndex}`].scrollIntoView({ block: "center" })
        }
        this.listSkeleton = false;
    },
    methods: {
        parseDateYYYYMMDD: parseDateYYYYMMDD,
        formatDateDDMMYYYY: formatDateDDMMYYYY,
        todayDate: todayDate,
        getSelectedStatusOne(id) {
          return this.deliveryNoteStatus.length > 0 ? this.deliveryNoteStatus.filter(item => item.id == id ) : ''
        },
        doubleClick(event, {item}) {
            const path = 'delivery/editDelivery/' + item.id;
            this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
            this.$store.commit('delivery/SET_ROWINDEX', item.id);
        },
        newPagination(val) {
            this.pagination = val
            this.numberOfRows = val.itemsLength
        },
        updateHeader() {
            this.$store.commit('deliveryReport/SHOW_HEADERS', this.headers.filter(s => this.selectedHeaders.some(d => d.value === s.value)))
            this.selectedHeaders = this.showHeaders
            this.dialog = false;
        },
        toggle() {
            this.$nextTick(() => {
                if (this.selectAllHeaders) {
                this.selectedHeaders = []
                } else {
                this.selectedHeaders = this.headers.slice()
                }
            })
        },
        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                this.customerLimit += 10;
                this.employeeLimit += 10;
                await this.$nextTick();
                ul.scrollTop = scrollTop;
            }
        },
        reset() {
            this.search = null;
            this.$store.commit('delivery/SET_DELIVERY_REPORT', [])
            this.page = 1;
            this.limit = 10;
            this.limitData = this.limit;
        },
        resetFilters() {
            this.selectedCustomer = null;
            this.selectedCustomerGroup = null;
            this.selectedEmployee = null;
            this.selectedStatus = [];
            this.delivery_date = '';
            this.delivery_validity = '';
            this.$store.commit("delivery/SET_DELIVERY_REPORT_QUERY", {});
        },
        downloadExcelFile() {
            let self = this;
            let data = self.deliveryReportList.data.map(o => Object.fromEntries(self.selectedHeaders.map(k => [k.text, k.value.includes('.')?o[`${k.value.split('.')[0]}`] && Object.keys(o[`${k.value.split('.')[0]}`]).length > 0 ? o[`${k.value.split('.')[0]}`][`${k.value.split('.')[1]}`] : o[`${k.value.split('.')[0]}`]:o[`${k.value}`]])))
            data.forEach((el)=> {
            for (let x in el) {
              if (!el[x]) {
                el[x] = ''
              }}
            })
            exportFromJSON({data: JSON.parse(JSON.stringify(data)), fileName: "deliveryReport", exportType: exportFromJSON.types.xls})
        },
        numberWithCommas(x) {
            return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
        },
        async paginate() {
            const {
                customer_id,
                customer_group_id,
                employee_id,
                selectedStatus,
                delivery_date,
                delivery_validity,
            } = this.params;
            // make params for where_and
            let concateWhereAnd = "";
            let whereAndVal = "|";
            concateWhereAnd += (selectedStatus || selectedStatus == 0) ? 'status,' : '';
            whereAndVal += (selectedStatus || selectedStatus == 0) ? selectedStatus+',' : '';
            concateWhereAnd += delivery_date ? "from_delivery_date," : "";
            concateWhereAnd += delivery_validity ? "to_delivery_date," : "";
            whereAndVal += delivery_date ? this.profileById.date_format ? this.parseDateYYYYMMDD(delivery_date)+',' : delivery_date+',' : '';
            whereAndVal += delivery_validity ? this.profileById.date_format ? this.parseDateYYYYMMDD(delivery_validity)+',' : delivery_validity+',' : '';
            concateWhereAnd += employee_id ? "delivery_notes.employee_id," : "";
            whereAndVal += employee_id ? employee_id + "," : "";

            const payloadData = {
              type: "Delivery",
              where: customer_id ? "customer_id|" + customer_id : undefined,
              whereAnd: customer_group_id
                ? "group_id|" + customer_group_id
                : undefined,
              where_and:
              concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
                  ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
                  : undefined,
              empty: 'group_id|' + 'NULL'
            }

            if (customer_group_id && customer_group_id === 111111111111111) {
              payloadData.empty, delete payloadData.whereAnd
            } else {
              payloadData.whereAnd, delete payloadData.empty
            }
            
            this.isProgressCircular = true;
            await this.$store.dispatch("delivery/GetDeliveryReport", payloadData).then(() => {
              this.isProgressCircular = false;
              this.$refs.search.focus();
            });
        },
    },
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>